/**********************
Global Styles
**********************/
*,
*:after,
*:before {
  box-sizing: border-box; }

/** Fixing bootstrap columns for safari **/
.row:after,
.row:before {
  display: inline-block !important; }

body {
  color: #231f20;
  font-size: 17px;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: sofia-pro, sans-serif;
  text-rendering: geometricPrecision;
  overflow-x: hidden; }

a,
a:focus,
a:hover,
a:visited {
  text-decoration: none;
  outline: 0; }

section {
  padding: 120px 0; }

button {
  border: 0;
  background-color: transparent; }
  button:hover {
    cursor: pointer; }
  button:focus {
    outline: 0; }

.container {
  max-width: 1140px; }

.section-title {
  font-size: 48px;
  line-height: 1.2;
  font-family: "Recoleta Bold";
  margin-bottom: 12px;
  text-rendering: geometricPrecision; }

.sub-heading {
  font-family: termina, sans-serif;
  font-size: 16px;
  line-height: 1.2;
  margin-bottom: 4px;
  text-transform: uppercase;
  color: #1ca9a0; }

strike {
  color: #797979; }

.img-container {
  text-align: center; }

.sale-text {
  font-weight: 700;
  font-size: 16px;
  margin: 20px 0;
  color: #2ed2ec; }

.modal {
  background-color: rgba(0, 0, 0, 0.8); }

.check {
  height: 20px !important;
  margin-right: 4px !important;
  margin-bottom: 0 !important; }

.text-black {
  color: #000 !important; }

.border-white {
  border: 2px solid #fff;
  border-radius: 5px; }

.border-black {
  border: 2px solid #000;
  border-radius: 5px; }

.link-color {
  color: #1ca9a0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-weight: 700;
  text-decoration: underline; }
  .link-color:hover {
    color: #1ca9a0;
    opacity: .8; }

/* Buttons */
.btn-prime,
.btn-prime:link,
.btn-prime:visited {
  padding: 15px 25px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border-radius: 40px;
  display: inline-block;
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
  vertical-align: middle;
  font-weight: 700;
  letter-spacing: 2px;
  box-shadow: none !important;
  outline: 0; }

.btn-prime:hover {
  opacity: 0.9; }

.btn-prime-dark,
.btn-prime-dark:focus,
.btn-prime-dark:hover {
  border: 2px solid #1ca9a0;
  background-color: #1ca9a0;
  color: #fff; }

.btn-prime-border,
.btn-prime-border:focus,
.btn-prime-border:hover {
  background-color: transparent;
  border: 2px solid transparent;
  color: #1ca9a0; }

.flex-container {
  -webkit-box-align: center;
  align-items: center; }

/** MEDIA QUERIES **/
@media only screen and (max-width: 1199px) {
  .container {
    max-width: 1060px; } }

@media only screen and (max-width: 991px) {
  section {
    padding: 50px 0; }
  .navbar-dark .navbar-nav .nav-link,
  .navbar-dark .navbar-nav .nav-link:active,
  .navbar-dark .navbar-nav .nav-link:focus,
  .navbar-dark .navbar-nav .nav-link:hover,
  .navbar-dark .navbar-nav .nav-link:visited {
    font-size: 12px; }
  .container {
    max-width: 890px; }
  .section-title {
    font-size: 38px; } }

@media only screen and (max-width: 767px) {
  #main-nav {
    display: none !important; }
  .container {
    max-width: 100%; }
  .mobile-menu img {
    height: 34px; }
  .mobile-menu input + label {
    right: 15px; } }

@media only screen and (max-width: 600px) {
  .section-title {
    font-size: 30px; }
  p {
    font-size: 16px; } }

@media only screen and (max-width: 475px) {
  .mobile-menu .navbar-brand {
    left: 15px; } }

@media only screen and (max-width: 450px) {
  section {
    padding: 50px 0; }
  .sub-heading {
    font-size: 18px;
    line-height: 24px; }
  .btn-prime,
  .btn-prime:link,
  .btn-prime:visited {
    font-size: 16px; }
  .sale-text {
    font-size: 22px; } }

@media only screen and (max-width: 375px) {
  .section-title {
    font-size: 22px !important; } }

.slick-dots li button:before {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-image: url("../img/dot-border.png");
  background-repeat: no-repeat;
  content: "";
  opacity: 1; }

.slick-dots li.slick-active button:before {
  background-image: url("../img/dot-full.png");
  background-repeat: no-repeat; }

.slick-list {
  margin: 0 -10px; }

.slick-slide {
  margin: 0 10px; }

.slick-track {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  margin-bottom: 30px;
  cursor: pointer; }

@font-face {
  font-family: "Recoleta Bold";
  src: url("../fonts/Recoleta-Bold.woff2") format("woff2"), url("../fonts/Recoleta-Bold.woff") format("woff"); }

/**********************
Navigation
**********************/
nav {
  padding: 0 !important; }

nav .nav-item {
  padding: 0; }

.navbar-dark .navbar-nav .nav-link {
  color: #fff;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding: 10px 15px !important; }

.buy-now {
  background-color: #1ca9a0 !important;
  color: #fff !important;
  border-radius: 40px; }

.navbar-dark .navbar-nav .nav-item,
.navbar-dark .navbar-nav .nav-item:active,
.navbar-dark .navbar-nav .nav-item:focus,
.navbar-dark .navbar-nav .nav-item:hover,
.navbar-dark .navbar-nav .nav-item:visited {
  color: #fff;
  font-size: 13px;
  outline: 0;
  letter-spacing: 0.05em;
  font-weight: 700;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase; }

.navbar-dark .navbar-nav .nav-link:hover {
  background-color: transparent;
  color: #231f20; }

.navbar-brand:hover,
.navbar-dark .navbar-brand {
  font-weight: 700;
  color: #1ca9a0; }
  .navbar-brand:hover img,
  .navbar-dark .navbar-brand img {
    max-width: 120px; }

.navbar-nav .dropdown-menu {
  left: auto;
  right: 0;
  border: 0;
  border-radius: 0;
  margin-top: 0; }

.dropdown-item {
  background-color: #fff; }

.dropdown-menu.show {
  background-color: #fff;
  padding: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.dropdown-item.active,
.dropdown-item:active {
  background-color: #f8f8f8;
  color: #212529; }

.dropdown-item:focus,
.dropdown-item:hover {
  color: #fff;
  background-color: #1ca9a0; }

.cta-btn {
  background-color: #1ca9a0;
  border-radius: 40px;
  line-height: inherit; }
  .cta-btn:hover {
    color: white !important;
    background: #1ca9a0; }
  .cta-btn .nav-link {
    color: #fff !important; }

#main-nav {
  background-color: transparent;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.buy-now {
  background: #1ca9a0 !important; }

/* Mobile Menu */
header {
  background-color: #fff;
  position: relative; }

@media screen and (min-width: 992px) {
  .mobile-menu {
    display: none; } }

@media screen and (max-width: 991px) {
  #main-nav {
    display: none; }
  .mobile-menu input ~ nav > ul > li > a.buy-now {
    max-width: 300px;
    margin: 0 auto; }
  .transparent-header {
    box-shadow: 2px 2px 5px 0 rgba(173, 173, 173, 0.3); }
  .mobile-nav .btn-prime.btn-prime-dark {
    color: #fff;
    font-size: 26px;
    width: 100%; }
  input#burger {
    display: none; }
  .mobile-menu .navbar-brand {
    font-weight: 700;
    color: #1ca9a0; }
  .mobile-menu input:not(:checked) .mobile-links {
    display: none; }
  .mobile-menu input + label {
    position: fixed;
    top: 20px;
    right: 20px;
    height: 20px;
    width: 34px;
    z-index: 5;
    padding: 0;
    margin-bottom: 0; }
  .mobile-menu input + label span {
    position: absolute;
    width: 100%;
    height: 2px;
    top: 60%;
    margin-top: -1px;
    left: 0;
    display: block;
    background: #fff;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
  .mobile-menu input + label span:first-child {
    top: 4px; }
  .mobile-menu input + label span:last-child {
    top: 20px; }
  .mobile-menu label:hover {
    cursor: pointer; }
  .mobile-menu input:checked + label span {
    opacity: 0;
    top: 50%; }
  .mobile-menu input:checked + label span:first-child {
    opacity: 1;
    -webkit-transform: rotate(405deg);
    transform: rotate(405deg); }
  .mobile-menu input:checked + label span:last-child {
    opacity: 1;
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg); }
  .mobile-menu input ~ nav {
    background-color: #231f20;
    box-shadow: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    z-index: 3;
    overflow: hidden; }
  .mobile-menu nav {
    position: relative; }
  .mobile-menu input ~ nav > ul {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 0;
    margin-bottom: 0;
    width: 80%; }
  .mobile-menu input ~ nav > ul > li {
    display: none; }
  .mobile-menu input ~ nav > ul > li > a {
    color: #fff;
    font-weight: 700;
    display: block;
    letter-spacing: 0.05em;
    padding: 15px;
    font-size: 22px;
    text-transform: uppercase; }
  .mobile-menu input:checked ~ nav {
    height: 100%;
    -webkit-transition-delay: 0s;
    transition-delay: 0s; }
  .mobile-links {
    list-style: none; }
  .mobile-menu input:checked ~ nav > ul > li {
    display: initial; }
  .mobile-menu .navbar-brand {
    position: absolute;
    top: 20px;
    left: 15px;
    z-index: 3000;
    height: 100%;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    max-width: 100px; } }

@media screen and (max-width: 575px) {
  .mobile-menu input ~ nav > ul > li > a,
  .mobile-nav .btn-prime.btn-prime-dark {
    font-size: 17px; } }

@media screen and (max-width: 450px) {
  .mobile-menu .navbar-brand img {
    height: 32px; } }

.transparent-header {
  padding: 10px 0; }

/**********************
Hero
**********************/
.big-hero {
  background-color: #f4f2e9;
  margin-top: 79px;
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  text-align: center;
  color: #fff;
  padding: 60px 0; }
  .big-hero::before {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: -100px;
    content: url("../img/img-left.png");
    z-index: 1; }
  .big-hero::after {
    position: absolute;
    bottom: -300px;
    right: -60px;
    -webkit-transform: rotate(-36deg);
    transform: rotate(-36deg);
    content: url("../img/img-right.png");
    z-index: 1; }
  .big-hero .container {
    position: relative;
    z-index: 2; }
    .big-hero .container .img-bg {
      position: absolute;
      top: -460px;
      right: auto;
      left: 6%;
      z-index: 1;
      max-width: 100%;
      width: 100%;
      z-index: -1; }
  .big-hero .row {
    padding: 0 15px; }
  .big-hero .content-area img {
    max-width: 660px; }
  .big-hero .section-title {
    color: #231f20;
    font-size: 64px;
    line-height: 1.1;
    margin-bottom: 16px; }
    .big-hero .section-title span {
      display: block; }
  .big-hero .sub-heading {
    font-size: 22px;
    line-height: 1.2;
    margin-bottom: 16px;
    color: #b29d62;
    text-transform: capitalize; }
    .big-hero .sub-heading span {
      line-height: 1.5; }
  .big-hero .text-area .color-text {
    color: #1ca9a0;
    font-size: 22px; }
    .big-hero .text-area .color-text.color-text-2 {
      color: #231f20;
      font-style: italic;
      font-size: 18px; }
  .big-hero .text-area .month {
    margin: 5px 0; }
  .big-hero .text-area .dates {
    font-size: 28px;
    font-weight: 700;
    line-height: 1.2;
    color: #231f20; }
  .big-hero .text-area .cta-container {
    margin-top: 20px; }
  .big-hero .img-container {
    max-width: 500px;
    margin: 0 auto 20px; }
    .big-hero .img-container .top {
      display: -webkit-box;
      display: flex;
      -webkit-box-align: end;
      align-items: flex-end;
      -webkit-box-pack: end;
      justify-content: flex-end; }
      .big-hero .img-container .top .img-left {
        position: relative; }
      .big-hero .img-container .top .img-right {
        position: relative;
        margin: 0 -200px 0 30px; }
  .big-hero .btn-prime {
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }

.wave-img .wave {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  margin-top: -13px; }

@media (max-width: 1610px) {
  .big-hero::after {
    max-width: 300px; }
  .big-hero::before {
    left: -250px; } }

@media (max-width: 1610px) {
  .big-hero::after {
    opacity: 0.3; }
  .big-hero::before {
    left: -365px;
    opacity: 0.3; } }

@media (max-width: 991px) {
  .big-hero {
    margin-top: 60px; }
  .big-hero::after,
  .big-hero::before {
    display: none; }
  .big-hero .sub-heading {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column; }
  .big-hero .sub-heading {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center; }
  .big-hero .sub-heading .bullet-p {
    display: none; }
  .big-hero .container .img-bg {
    position: relative;
    top: 0;
    left: 0; }
  .big-hero .text-area {
    margin: 0 auto 20px; }
  .big-hero .section-title {
    font-size: 50px; }
  .big-hero .dates {
    color: #101D4F; } }

@media (max-width: 767px) {
  .big-hero .img-container {
    max-width: 400px; }
  .big-hero .section-title {
    font-size: 40px; } }

@media (max-width: 600px) {
  .big-hero .img-container {
    margin: 0 auto 20px; } }

@media (max-width: 450px) {
  .big-hero .img-container {
    max-width: 200px; }
  .big-hero .section-title {
    font-size: 32px !important; }
  .big-hero .text-area .dates {
    font-size: 23px;
    line-height: 1.4;
    margin: 5px 0; }
    .big-hero .text-area .dates .month:not(:last-child) {
      margin-bottom: 16px; }
    .big-hero .text-area .dates span {
      display: block; }
  .big-hero .sub-heading {
    font-size: 18px; } }

/******************************
Text Block
******************************/
.text-block {
  text-align: center; }
  .text-block .link-color {
    text-decoration: underline; }
  .text-block .section-header {
    margin-bottom: 30px; }
  .text-block .cta-container {
    text-align: center;
    margin-top: 30px; }
  .text-block.text-bottom {
    background-color: #f8f8f8; }

@media screen and (max-width: 1199px) {
  .text-block.intro {
    margin-top: 130px; } }

@media screen and (max-width: 991px) {
  .text-block.intro {
    margin-top: 0;
    padding-top: 50px; }
  .text-block .section-header {
    margin-bottom: 10px; } }

/******************************
Video
******************************/
.video {
  text-align: center; }
  .video .video-inner {
    background-color: #f4f2e9; }
  .video img.wave {
    margin-bottom: -2px; }
  .video .reverse {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    margin-top: -13px; }
  .video .graphic-section {
    margin: 50px 0; }
  .video .main-video {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.4); }
    .video .main-video .video-container {
      position: relative;
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center; }
    .video .main-video .img-container {
      position: relative; }
      .video .main-video .img-container::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: 0; }
    .video .main-video .play-btn {
      position: absolute;
      max-width: 80px;
      z-index: 1; }
  .video .cta-container {
    margin-top: 30px;
    padding-bottom: 30px; }

@media (max-width: 991px) {
  .video .section-header {
    padding-top: 30px; }
  .video .graphic-section {
    margin: 20px 0; }
  .video .cta-container {
    padding-bottom: 30px; } }

@media (max-width: 600px) {
  .video .main-video .play-btn {
    max-width: 50px; } }

.video.extended-clip {
  background-color: #fff; }
  .video.extended-clip .section-title {
    color: #fff; }
  .video.extended-clip .video-inner {
    background-color: #231f20;
    padding: 120px 0; }
  .video.extended-clip .cta-container {
    padding: 0; }

@media (max-width: 991px) {
  .video.extended-clip .video-inner {
    padding: 60px 0 80px; }
  .video.extended-clip .section-header {
    padding-top: 0;
    margin-bottom: 30px; } }

/******************************
Learning
******************************/
.learning {
  text-align: center; }
  .learning .section-header .section-title {
    margin-bottom: 20px; }
  .learning .grid-row {
    margin: 40px -15px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* Size of items defined inside container */ }
    .learning .grid-row .item {
      border: 1px solid rgba(0, 0, 0, 0.08);
      padding: 20px;
      min-height: 300px;
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center; }
      .learning .grid-row .item.border-left {
        border-left: none !important; }
      .learning .grid-row .item.border-right {
        border-right: none !important; }
      .learning .grid-row .item.border-bottom {
        border-bottom: none !important; }
      .learning .grid-row .item.border-top {
        border-top: none !important; }
      .learning .grid-row .item .title {
        font-weight: 700;
        line-height: 1.4;
        margin-bottom: 16px;
        font-size: 28px; }
        .learning .grid-row .item .title span {
          font-family: termina, sans-serif;
          color: #1ca9a0;
          display: block;
          font-size: 18px; }
  .learning .cta-container {
    margin-top: 50px; }

@media (max-width: 1199px) {
  .learning .grid-row .item {
    -webkit-box-align: start;
    align-items: flex-start;
    min-height: 250px; } }

@media (max-width: 767px) {
  .learning .grid-row {
    grid-template-columns: 1fr;
    max-width: 800px;
    margin: 20px auto; }
  .learning .grid-row .item {
    padding: 20px 15px;
    min-height: auto;
    border: 0; }
    .learning .grid-row .item:not(:last-child) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.08) !important; }
  .learning .cta-container {
    margin-top: 20px; } }

@media (max-width: 600px) {
  .learning .grid-row {
    margin: 0 auto 20px; }
  .learning .grid-row .item .title {
    font-size: 24px; }
  .learning .grid-row .item .title span {
    font-size: 16px; } }

/**********************
Event Guide
**********************/
.event-guide {
  background-color: #231f20;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 170px;
  color: #fff; }
  .event-guide img {
    margin-top: -170px;
    margin-bottom: 40px;
    max-width: 300px; }
  .event-guide .section-title {
    margin-bottom: 19px; }
  .event-guide .sub-title {
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: 700;
    color: #615e5c; }

/* Media Queries */
@media only screen and (max-width: 991px) {
  .event-guide .section-title {
    font-size: 50px; } }

@media only screen and (max-width: 600px) {
  .event-guide .section-title {
    font-size: 30px; }
  .event-guide .sub-title {
    font-size: 19px; }
  .event-guide img {
    margin-bottom: 20px; } }

@media only screen and (max-width: 375px) {
  .event-guide .sub-title {
    font-size: 17px; } }

/******************************
About
******************************/
.about {
  background-color: #f4f2e9;
  padding: 0; }
  .about .section-header {
    margin-bottom: 20px; }

@media (max-width: 991px) {
  .about {
    text-align: center; }
    .about .img-container {
      margin-bottom: 40px; } }

/**********************
Buy Stream
**********************/
.purchase {
  text-align: center;
  background-color: #f4f2e9; }
  .purchase .section-header {
    margin-bottom: 40px; }
  .purchase .grid-row {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 30px; }
    .purchase .grid-row .item {
      padding: 0;
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column; }
      .purchase .grid-row .item .img-container {
        margin: 20px 0; }
      .purchase .grid-row .item .license {
        color: #1ca9a0;
        letter-spacing: 1px;
        font-family: "Recoleta Bold";
        text-transform: uppercase; }
      .purchase .grid-row .item .price {
        font-weight: 700;
        font-size: 80px;
        color: #231f20; }
        .purchase .grid-row .item .price span {
          text-decoration: line-through;
          opacity: 0.3; }
      .purchase .grid-row .item .des {
        padding: 15px; }
        .purchase .grid-row .item .des .title {
          font-weight: 700;
          color: #000; }
      .purchase .grid-row .item .cta-container {
        margin-top: auto;
        width: 100%; }
        .purchase .grid-row .item .cta-container .btn-prime {
          display: block; }

@media only screen and (max-width: 991px) {
  .purchase .grid-row {
    grid-template-columns: 1fr;
    grid-gap: 50px;
    max-width: 600px;
    margin: 0 auto; } }

/*************************
  Pricing Modal
  *************************/
.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  margin-top: 8px;
  margin-bottom: 28px; }

.bootstrap-select .dropdown-toggle .filter-option {
  border-radius: 0; }

.bootstrap-select > .dropdown-toggle {
  padding: 13px;
  border: solid 1px #101D4F; }

.bootstrap-select .dropdown-menu li a {
  padding: 10px 13px; }

.btn-light:hover {
  color: #fff;
  background-color: #1ca9a0;
  border-color: #1ca9a0; }

#popupModal {
  text-align: center;
  padding-right: 0 !important; }
  #popupModal button:focus {
    outline: 0; }
  #popupModal button.close {
    opacity: 1; }
  #popupModal a:not([href]):hover {
    color: #fff; }
  #popupModal .hide {
    display: none; }
  #popupModal .modal-content {
    border-radius: 0;
    border: 0;
    background-color: #f8f8f8; }
    #popupModal .modal-content .section-title {
      font-size: 24px; }
  #popupModal .modal-header {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-color: #1ca9a0;
    background: #1ca9a0; }
    #popupModal .modal-header img {
      max-width: 120px; }
    #popupModal .modal-header .modal-header .close {
      margin: 0; }
  #popupModal .modal-title {
    font-size: 30px;
    font-weight: 400; }
  #popupModal .modal-body {
    background-color: #fff;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.08);
    border: none;
    position: relative;
    -webkit-box-flex: 1;
    flex: 1 1 auto;
    max-width: 90%;
    margin: 40px auto;
    padding: 40px 20px;
    border-radius: 10px; }
    #popupModal .modal-body .modal-price {
      padding: 15px;
      background: #231f20;
      font-size: 40px;
      line-height: 1.2;
      color: #fff; }
    #popupModal .modal-body .modal-text {
      padding: 30px 15px; }
    #popupModal .modal-body .buy-license a {
      padding: 15px 25px;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      border-radius: 40px;
      display: inline-block;
      font-size: 14px;
      text-transform: uppercase;
      text-align: center;
      vertical-align: middle;
      font-weight: 700;
      letter-spacing: 2px;
      box-shadow: none !important;
      outline: 0;
      border: 2px solid #1ca9a0;
      background-color: #1ca9a0;
      color: #fff;
      display: block;
      border-radius: 0; }
  #popupModal .content .title {
    font-weight: bold;
    font-size: 17px;
    letter-spacing: 1px;
    border-radius: 8px 8px 0 0;
    margin-top: 0;
    margin-bottom: 10px; }
  #popupModal .content .license-container p {
    margin: 0; }
  #popupModal .content .license-container .box .header .price-head {
    background-color: #000;
    width: 100%;
    padding: 0;
    color: #fff;
    font-weight: 600;
    padding: 30px; }
    #popupModal .content .license-container .box .header .price-head .price {
      color: #fff;
      font-size: 40px; }
    #popupModal .content .license-container .box .header .price-head p {
      font-size: 35px;
      font-weight: bold;
      margin: 0; }
  #popupModal .content .license-container .box .body {
    padding: 15px;
    border-left: 1px solid #eee;
    border-right: 1px solid #eee; }
  #popupModal .content .license-container .box .card-footer {
    padding: 0;
    border-top: 0; }
    #popupModal .content .license-container .box .card-footer a {
      display: block;
      text-decoration: none;
      text-transform: uppercase; }

/**********************
reviews
**********************/
.reviews {
  background: #b29d62;
  font-family: proxima-nova, sans-serif;
  color: #f4f2e9; }
  .reviews .section-header {
    margin-bottom: 60px;
    text-align: center; }
  .reviews .slick-track {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center; }
  .reviews .slick-dotted.slick-slider {
    margin-bottom: 0; }
  .reviews .product-next,
  .reviews .product-prev {
    cursor: pointer; }
  .reviews .quote {
    font-size: 30px;
    line-height: 1.2;
    font-weight: 700;
    font-family: "Recoleta Bold"; }
  .reviews .reviewer {
    font-size: 17px;
    margin-top: 30px;
    text-align: right;
    font-family: termina, sans-serif;
    font-weight: 700;
    color: #231f20; }
    .reviews .reviewer span {
      display: block; }

@media only screen and (max-width: 991px) {
  .reviews .section-header {
    margin-bottom: 30px; }
    .reviews .section-header .section-title {
      margin-bottom: 0; }
  .reviews {
    text-align: center; }
    .reviews .right {
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
      flex-direction: column-reverse; }
    .reviews .reviewer {
      text-align: center; }
  .reviews .item img {
    margin: 0 auto 40px;
    max-width: 400px;
    width: 100%; } }

@media only screen and (max-width: 600px) {
  .reviews .item img {
    margin-bottom: 20px;
    max-width: 200px; }
  .reviews .quote {
    font-size: 22px; }
  .reviews .reviewer {
    font-size: 16px; } }

/******************************
FAQ
******************************/
.accordion {
  margin-top: 50px; }
  .accordion .link-color {
    text-decoration: none; }

.rotated {
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  /* IE 9 */
  -moz-transform: rotate(45deg);
  /* Firefox */
  -webkit-transform: rotate(45deg);
  /* Safari and Chrome */
  -o-transform: rotate(45deg);
  /* Opera */ }

#accordion {
  background-color: transparent;
  text-align: left; }
  #accordion .section-header {
    text-align: center;
    margin-bottom: 50px; }
  #accordion .times {
    list-style: none;
    padding: 0; }
  #accordion .card {
    border: 0;
    border-radius: 0;
    margin-bottom: 30px;
    box-shadow: 2px 2px 5px 0 rgba(173, 173, 173, 0.2); }
  #accordion .card-header {
    padding: 20px 15px;
    color: #fff;
    font-size: 20px;
    background-color: #1ca9a0;
    cursor: pointer;
    text-transform: capitalize;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between; }
    #accordion .card-header .card-title {
      margin-bottom: 0; }
    #accordion .card-header:first-child {
      border-radius: 0; }

@media only screen and (max-width: 450px) {
  #accordion .card-header .card-title {
    margin-bottom: 0;
    font-size: 16px; }
  #accordion .section-header {
    margin-bottom: 30px; } }

/**********************
Footer
**********************/
footer {
  background-color: #231f20;
  color: #fff;
  padding: 60px 0;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600; }
  footer .btn-prime {
    background-color: #b29d62;
    color: #fff; }
  footer .footer-content {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between; }
    footer .footer-content.footer-top {
      margin-bottom: 20px; }
      footer .footer-content.footer-top .img-container {
        max-width: 150px; }
  footer .social {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin: 15px 0; }
    footer .social svg {
      margin: 0 7px;
      font-size: 18px;
      color: #fff; }

@media only screen and (max-width: 700px) {
  footer {
    text-align: center; }
  footer .footer-content {
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column; }
  footer .img-container {
    margin-bottom: 30px; } }

/******************************
Site Specific
******************************/
.nav-item {
  padding: .5rem 1rem;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-weight: 700; }
  .nav-item:hover {
    color: #101D4F !important; }

header {
  background-color: #b29d62;
  box-shadow: none;
  z-index: 1;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.header-active {
  background-color: #b29d62;
  padding: 10px 0 !important; }

.platforms .logos-container {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  font-size: 50px;
  line-height: 1;
  margin-bottom: 12px; }
  .platforms .logos-container i {
    margin-right: 8px; }
    .platforms .logos-container i:last-child {
      margin-right: 0; }

@media (max-width: 768px) {
  .section-title {
    font-size: 42px; } }

@media (max-width: 700px) {
  .section-title {
    font-size: 32px; } }

@media (max-width: 450px) {
  .section-title {
    font-size: 30px; }
  .sale-text {
    font-size: 18px; } }
